<template>
    <div>
        <v-autocomplete
            v-if="multipleSelect"
            v-model="search_result"
            :items="searchResult"
            :search-input.sync="search"
            :append-icon="displayAddIcon ? 'add_circle' : ''"
            @click:append="onAppendIconClick()"
            @change="onChangeInMultiple"
            chips
            color="blue-grey lighten-2"
            :label="label"
            item-text="name"
            item-value="name"
            dense
            outlined
            multiple
            clearable
        >
            <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                 
                  {{ data.item.name }}
                </v-chip>
            </template>
            <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
        </v-autocomplete>
        <v-autocomplete
            v-if="!multipleSelect"
            v-model="search_result"
            :items="searchResult"
            :search-input.sync="search"
            :append-icon="displayAddIcon ? 'add_circle' : ''"
            color="blue-grey lighten-2"
            :label="label"
            item-text="name"
            item-value="name"
            dense
            outlined
            clearable
            @click:append="onAppendIconClick()"
            @change="onChange"
            @click:clear="$emit('onClear')"
        >
           
        </v-autocomplete>
    </div>
</template>

<script>
let typingTimer;
let doneTypingInterval = 400;

export default {
    // props: ["disabled","label",'searchType','displayAddIcon','editData'],
    props:{
        disabled:{
            default:false
        },
        label:{
            required:true
        },
        searchType:{
            required:true
        },
        displayAddIcon:{
            required:false,
            default:'',
        },
        editData:{
            required:false
        },
        displayClose:{
            default:true,
        },
        multipleSelect:{
            default:false,
        }

    },
    data() {
        return {
            loading: false,
            author_list: [],
            search: "",
            searchResult:[],
            search_result:'',
        };
    },
    watch: {
        search(val) {
            if (!val) return;

            if (val.length < 1) return;

            if (this.loading) return;

            let $this = this;
            clearTimeout(typingTimer);
            typingTimer = setTimeout(function() {
                $this.searchForData($this.searchType);
                // if($this.searchType === 'author'){
                //     $this.searchAuthor();
                // }else if($this.searchType === 'category'){
                //     $this.searchCategory();
                // }else if($this.searchType === 'publisher'){
                //     $this.searchPublisher();
                // }else if ($this.searchType === 'vendor'){
                //    $this.searchVendor();
                // }else if($this.searchType === 'donor'){
                //     $this.searchForData($this.searchType);
                // }else{
                //     return;
                // }
            }, doneTypingInterval);
        }

    },
    mounted(){
        this.searchResult=[];
        this.search_result = [];
        if(this.editData && this.searchType === 'author'){
            for (let i = 0; i < this.editData.author_id.length; i++) {
                this.searchResult.push({
                    id:this.editData.author_id[i],
                    name:this.editData.author_name[i],
    
                })
            }
            this.search_result = this.editData.author_name;
        }
        if(this.editData && this.searchType === 'vendor'){
            this.searchResult.push({
                name:this.editData.vendor_name,
                id:this.editData.vendor_id
            })
            this.search_result = this.editData.vendor_name;
        }
        if(this.editData && this.searchType === 'publisher'){
            this.searchResult.push({
                name:this.editData.publisher_name,
                id:this.editData.publisher_id
            })
            this.search_result = this.editData.publisher_name;
        }
        if(this.editData && this.searchType === 'donor'){
            this.searchResult.push({
                name:this.editData.donor_name,
                id:this.editData.donor_id
            })
            this.search_result = this.editData.donor_name;
        }
        if(this.editData && this.searchType === 'category'){
            this.searchResult.push({
                name:this.editData.category_name,
                id:this.editData.category_id
            })
            this.search_result = this.editData.category_name;
        }
        
    },
    methods:{
        remove (item) {
            this.searchResult = this.searchResult.filter(it => it.id != item.id);
            this.search_result=this.searchResult.map(item=>item.name);
            let selected_data = null
            this.searchResult.map((item,index) => {
                if (item.name === this.search_result[index]) {
                    selected_data = item;
                }
            });
            this.$emit("onItemRemove", selected_data,this.searchType);
        },
        searchForData(searchType){
            if(this.search == '' && this.sear)c
            let url = "/library/api/"+searchType+"?search="+this.search;
            this.$rest.get(url)
            .then(({ data }) => {
                this.searchResult = data.data;
            });

        },
        // searchAuthor(){
        //     this.$rest.get("/library/api/author?search="+this.search)
        //     .then(({ data }) => {
        //         this.searchResult = data.data;
        //     });
        // },
        // searchCategory(){
        //     this.$rest.get("/library/api/category?search="+this.search)
        //     .then(({ data }) => {
        //         this.searchResult = data.data;
        //     });
        // },
        // searchPublisher(){
        //     this.$rest.get("/library/api/publisher?search="+this.search).then(({ data }) => {
        //         this.searchResult = data.data;
        //     });
        // },
        // searchVendor(){
        //     this.$rest.get("/library/api/vendor?search="+this.search).then(({ data }) => {
        //         this.searchResult = data.data;
        //     });
        // },
        // searchDonor(){
        //     this.$rest.get("/library/api/donor?search="+this.search).then(({ data }) => {
        //         this.searchResult = data.data;
        //     });
        // },
        onChangeInMultiple(){
            let selected_data = null
            this.searchResult.map((item,index) => {
                if (item.name === this.search_result[index]) {
                    selected_data = item;
                }
            });
            this.$emit("onSelectResult", selected_data,this.searchType);
        },
        onChange(){
            let selected_data = null
            
            this.searchResult.map((item) => {
               if (item.name === this.search_result) {
                    selected_data = item;
               }
            });
            this.$emit("onSelectResult", selected_data,this.searchType);

        },
        onAppendIconClick(){
            this.$emit("onIconClick",this.searchType)
        }
        
    }
}
</script>
